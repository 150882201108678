import "../scss/_mdi.scss";
import "../../../../../wp/wp-includes/css/dist/block-library/style.min.css";

import PhotoSwipe from "photoswipe/dist/photoswipe.js";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default.js";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

function initializePhotoSwipe()
{
	Array
		.from(document.querySelectorAll("[data-photoswipe]"))
		.forEach(gallery => initializeGallery(gallery));
}

function initializeGallery(container)
{
	const items = [];
	const pswp = document.querySelector(".pswp");
	const photoLinks = container.querySelectorAll(".photo-link");

	const openGallery = (evt, launchFrom) =>
	{
		const gallery = new PhotoSwipe(pswp, PhotoSwipeUI_Default, items, {

			index: launchFrom,
			showHideOpacity: true,

			getThumbBoundsFn(index)
			{
				const photo = photoLinks[index];
				const {top, left, height, width} = photo.getBoundingClientRect();

				return {
					x: left,
					y: top + (document.scrollingElement || document.documentElement).scrollTop,
					w: width,
					h: height
				};
			}

		});
		gallery.init();

		evt.preventDefault();
		evt.stopPropagation();
	};

	photoLinks.forEach((photo, index) =>
	{
		const height = parseInt(photo.dataset.height);
		const width = parseInt(photo.dataset.width);
		const src = photo.dataset.largeUrl;

		items.push({
			src: src,
			w: width,
			h: height
		});

		photo.addEventListener("click", evt => openGallery(evt, index));
	});
}

window.addEventListener("load", () =>
{
	initializePhotoSwipe();
});
